import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pager from "../components/pager"

const Blog = ({ data, pageContext }) => {
  const posts = data.allMicrocmsPosts.edges
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout title={siteTitle}>
      <Seo title="茨城夫婦のグルメブログ" />
      <p tw="pl-4 underline">
        <Link to="/">記事一覧</Link>
      </p>

      <ol tw="divide-y mb-20" style={{ listStyle: `none` }}>
        {posts.map(item => {
          const post = item.node
          const title = post.title
          const image = getImage(post.localImage)

          return (
            <li key={post.postsId}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <Link to={`/posts/${post.postsId}`} itemProp="url">
                    <div tw="sm:flex sm:flex-wrap">
                      <picture tw="w-full sm:w-1/2 max-w-xs">
                        <GatsbyImage
                          image={image}
                          alt={post.localImage}
                          tw="rounded-md mx-auto object-cover "
                        />
                      </picture>
                      <div tw="w-full sm:w-1/2 mx-auto sm:mx-0 pl-4 pt-4 sm:pt-0">
                        <h2 tw="text-2xl md:text-4xl">{title}</h2>
                        <span>{post.publishedAt}</span>
                      </div>
                    </div>
                  </Link>
                </header>
              </article>
            </li>
          )
        })}
      </ol>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const query = graphql`
  query Pagenation($skip: Int!, $limit: Int!) {
    allMicrocmsPosts(
      filter: { isFeatured: { eq: false } }
      sort: { fields: [createdAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          author
          category
          postsId
          image {
            url
          }
          localImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          publishedAt(formatString: "YYYY/MM/DD", locale: "ja")
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Blog
